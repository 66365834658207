<script>

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkDialog from "@components/general/AkDialog";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import archiveService from "@services/archiveService";

export default {
  components: {AkFormList, AkDialog},
  mixins: [randomRef, roleMixin],
  metaInfo() {
    return {
      title: "home",
    }
  },
  data() {
		return {
      archiveInProgress : false
    }
	},
  methods: {
    confirmArchive() {
      this.$refs.dialogArchive.show();
    },
    archive() {
      this.archiveInProgress = true;
      archiveService.archiveAll().then(() => {
          this.getRef().success(this.$t('archived'));
          this.archiveInProgress = false;
        })
        .catch((error) => {
          this.archiveInProgress = false;
          console.log(error);
        });
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormList
    :ref="ref"
    title="Bienvenue!"
    :displayGoBack=false>
    <template v-slot:action>
      <button v-if="!roleLoading && permissionList.includes('ADMIN_MANAGE')" @click=confirmArchive() class="btn btn-danger" :disabled="archiveInProgress" style="margin-left: 10px">
        <i class="pi pi-spin pi-spinner mr-1" v-if="archiveInProgress"></i>{{ $t('archive') }}
      </button>
    </template>
    <template v-slot:list>
      <div>
        <div class="row align-items-center mb-2 mb-sm-3">
          <div class="col-xxl-5 col-xl-5 mr-auto">
            <p>Vous êtes sur l'espace collaboratif VH de la DiRIF</p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:extra>
      <AkDialog
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('archive_dialog')"
        :validate-label="$t('yes')"
        @validate=archive
        ref="dialogArchive"
        width="450px">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('confirm_archive') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>